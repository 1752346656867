import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Select from "react-dropdown-select";
//import { useSelector } from "react-redux";

export const UIMeses = ({ periodoProgramado, handleInputChange }) => {
  //const { actaActiva } = useSelector((state) => state.planificacion);
  const { añoLectivo  } = useSelector( state => state.auth );

  const handleSelectChange = ( valor ) => {
      const e = { target: { 'name': 'periodoProgramado', 'value': valor[0].mes }};
  
      handleInputChange( e );
  }
  const meses = [ { id: 1, mes : `Enero/${añoLectivo}` }, 
                  { id: 2, mes : `Febrero/${añoLectivo}` },
                  { id: 3, mes : `Marzo/${añoLectivo}` },
                  { id: 4, mes : `Abril/${añoLectivo}` },
                  { id: 5, mes : `Mayo/${añoLectivo}` }, 
                  { id: 6, mes : `Junio/${añoLectivo}` },
                  { id: 7, mes : `Julio/${añoLectivo}` }, 
                  { id: 8, mes : `Agosto/${añoLectivo}` },
                  { id: 9, mes : `Septiembre/${añoLectivo}` }, 
                  { id: 10, mes : `Octubre/${añoLectivo}` },
                  { id: 11, mes : `Noviembre/${añoLectivo}` }, 
                  { id: 12, mes : `Diciembre/${añoLectivo}` }];
  
  let mesActual = [{ id: (new Date()).getMonth() + 1, mes: meses[(new Date()).getMonth()].mes }];

  if (periodoProgramado.length > 0) {
    const mes = meses.filter(item => item.mes === periodoProgramado ) 
    mesActual = mes;  
  }
  
  useEffect(() => {
    handleSelectChange( mesActual );
  }, [])

  return (
    <Grid item xs={12} >
      <Grid item xs={12} >
        Periodo
      </Grid>
        <Grid container>
          <Grid item xs={12} >
            <Select options={ meses } 
              values={ mesActual }
              valueField='id'
              labelField='mes'
              searchBy='mes'
              onChange={ ( e ) => handleSelectChange( e )} />
          </Grid>
        </Grid>
    </Grid>
  );
};